/* Tailwind config */

/* Home.jsx */
.dateCard {
  display: flex;
  gap: 0.5rem;
  margin: 1rem;
  list-style: none;
  text-align: center;
  li {
    border: 1px solid black;
    border-radius: 10px;
    padding: 0.25rem;
    cursor: pointer;
  }
  li:hover {
    background-color: #eee;
  }
}

.btn-container {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}

.logCard {
  border: 1px solid black;
  width: 80%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 10px;
  cursor: pointer;
}
.logCard:hover {
  background-color: #eee;
}

.takeQuestionUl {
  list-style: none;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  li {
    border: 1px solid black;
    width: 50%;
    padding: 0.25rem;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
  }
}

/* CreateDrug.jsx */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* ViewDrug.jsx */
.drugList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.drugCard {
  border: 1px solid black;
  border-radius: 10px;
  width: 90%;
  padding: 0 1rem;
  cursor: pointer;
}
.drugCard:hover {
  background-color: #eee;
}
